figure:before,
figure:after,
.l-header:before,
.l-header:after,
.l-footer:before,
.l-footer:after,
.c-products:before,
.c-products:after {
  display: block;
  display: table;
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: "";
}
figure,
.c-products {
  zoom: 1;
}
figure:after,
.c-products:after {
  clear: both;
}
@font-face {
  font-family: 'DinTextPro';
  src: local('DinTextPro'), url('../fonts/pfdintextpro/light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'DinTextPro';
  src: local('DinTextPro'), url('../fonts/pfdintextpro/regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: bold;
}
dfn {
  font-style: italic;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
mark {
  background: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 1em 40px;
}
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}
pre {
  overflow: auto;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
}
optgroup {
  font-weight: bold;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}
font-size: 16px;
font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
line-height: 1;
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
hr,
ol,
ul,
dl,
dt,
dd,
address,
figure,
figcaption,
table,
p {
  font-size: 1rem;
  line-height: 1.25;
  text-indent: 0;
  widows: 4;
  orphans: 4;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  page-break-before: always;
  page-break-inside: avoid;
  font-weight: 400;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  letter-spacing: -0.035em;
}
h6 {
  font-size: 0.875rem;
  line-height: 1.25;
}
h5 {
  font-size: 1rem;
  line-height: 1.25;
}
h4 {
  font-size: 1.25rem;
  line-height: 1.25;
}
h3 {
  font-size: 1.5rem;
  line-height: 1.25;
}
h2 {
  font-size: 1.75rem;
  line-height: 1.25;
}
h1 {
  font-size: 2rem;
  line-height: 1.25;
}
h6 {
  letter-spacing: 0.125em;
  text-transform: uppercase;
}
blockquote {
  margin-left: 3ch;
  margin-right: 3ch;
}
cite {
  font-style: normal;
}
q {
  display: inline;
  font-style: italic;
  quotes: '\AB' '\BB' '\201E' '\201C';
}
q:before {
  content: open-quote;
}
q:after {
  content: close-quote;
}
ol,
ul {
  padding-left: 3ch;
}
li {
  margin: 0;
}
ul {
  list-style: disc;
}
ol {
  list-style: decimal;
  counter-reset: list__item;
}
ol > li {
  counter-increment: list__item;
}
.ol--unstyled {
  list-style: none;
}
.ol--nested {
  list-style: none !important;
}
.ol--nested li,
.ol--nested li ol li {
  position: relative;
}
.ol--nested li:before,
.ol--nested li ol li:before {
  display: block;
  position: absolute;
  right: 100%;
  padding-right: 1ch;
  text-align: right;
  word-break: normal;
  content: counters(list__item, ".") ".";
}
.ol--nested > li > ul,
.ol--nested > li > ol {
  margin-top: 0;
  margin-bottom: 0;
}
.ol--nested li ol {
  list-style: none !important;
}
.ol--nested li ol li:before {
  content: counters(list__item, ".") "";
}
.ol--type_armenian {
  list-style-type: armenian;
}
.ol--type_decimal {
  list-style-type: decimal;
}
.ol--type_decimal-leading-zero {
  list-style-type: decimal-leading-zero;
}
.ol--type_georgian {
  list-style-type: georgian;
}
.ol--type_lower-greek {
  list-style-type: lower-greek;
}
.ol--type_lower-latin {
  list-style-type: lower-latin;
}
.ol--type_lower-roman {
  list-style-type: lower-roman;
}
.ol--type_upper-latin {
  list-style-type: upper-latin;
}
.ol--type_upper-roman {
  list-style-type: upper-roman;
}
small {
  font-size: 0.875rem;
  line-height: 1;
}
dt {
  margin-bottom: 0;
  font-weight: bold;
}
dd {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}
sub,
sup {
  margin: -1em 0;
  line-height: 1px;
  font-size: 0.75rem;
}
b,
strong {
  font-weight: bold;
}
i,
em,
var,
dfn {
  font-style: italic;
}
s,
strike,
del {
  text-decoration: line-through;
}
tt,
code,
output,
pre,
samp,
kbd {
  font-family: 'Consolas', 'Courier New', monospace;
  font-size: inherit;
}
u,
ins {
  text-decoration: underline;
}
abbr {
  border-bottom: 1px dashed rgba(0,0,0,0.4);
  cursor: help;
}
address {
  font-style: normal;
}
a {
  font-family: inherit;
  text-decoration: underline;
  color: inherit;
  cursor: pointer;
}
a:hover {
  text-decoration: none;
}
counter-reset: figure__item;
figure {
/* @extend $clearfix */
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  counter-increment: figure__item;
}
figure > img {
  max-width: 100%;
  vertical-align: middle;
}
figcaption {
  margin-bottom: 0;
}
figcaption:before {
  opacity: 0.75;
  content: 'Рисунок' " " counters(figure__item, ".") ". ";
}
table {
  width: 100%;
  border: 0 solid transparent;
  border-collapse: collapse;
  font-size: inherit;
  text-align: left;
  vertical-align: middle;
}
table caption,
table td,
table th,
table tr {
  border: 0 solid transparent;
  font-size: inherit;
  vertical-align: middle;
}
table caption:not([align]),
table td:not([align]),
table th:not([align]),
table tr:not([align]) {
  text-align: left;
}
table th {
  font-weight: bold;
}
html {
  color: #2c5cb8;
}
html {
  box-sizing: border-box;
}
html *,
html *:before,
html *:after {
  box-sizing: inherit;
}
html img {
  box-sizing: content-box;
}
body {
  font-family: DinTextPro;
  min-width: 1220px;
}
body.is-not-scrollable {
  overflow: hidden;
}
a {
  text-decoration: none;
}
.l-panel--top-bar {
  font-size: 11px;
  background-color: #fff;
  color: #7babe3;
}
.l-panel--top-bar .l-panel__container {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.l-panel--header {
  background-color: #98d2f1;
  background-image: url("../img/bg-orange.png");
  background-repeat: repeat;
  color: #fff;
}
.l-panel--header .l-panel__container {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.l-panel--header .l-panel__bg {
  background: url("../img/gr-orange.png") repeat-y center;
}
.l-panel--content {
  position: relative;
  z-index: 3;
  margin-top: -80px;
  background: #79d3f3 url("../img/bg-pt-blue.png") repeat;
}
.l-panel--content .l-panel__container {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.l-panel--footer {
  background: #9e9e9e url("../img/bg-pt-grey.png") repeat;
}
.l-panel__container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  width: 1220px;
}
.l-header {
  zoom: 1;
}
.l-header:after {
  clear: both;
}
.l-header__container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  width: 1000px;
  position: relative;
}
.l-header__bg {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  height: 100vh;
  background: url("../img/kdpv.png") no-repeat center center;
  background-size: cover;
}
.l-header__lead {
  position: absolute;
  margin-left: -84px;
  left: 50%;
  bottom: 136px;
  font-size: 27px;
  color: #fff;
}
.l-header__bar {
  position: relative;
  height: 85px;
  margin-bottom: -85px;
  background: #fff;
  color: #7babe3;
  z-index: 2;
}
.l-header__logo {
  float: left;
  margin-top: 14px;
  background: url("../img/logo.png") no-repeat center center;
  width: 261px;
  height: 56px;
}
.l-header__text {
  float: left;
  margin-top: 24px;
  margin-left: 30px;
  font-size: 14px;
}
.l-header__contact {
  width: 270px;
  float: right;
  margin-top: 10px;
  font-size: 15px;
  line-height: 31px;
}
.l-header__contact .i:before {
  margin-right: 9px;
}
.l-header__phone,
.l-header__social {
  float: left;
}
.l-header__phone {
  width: 55%;
  font-size: 16px;
}
.l-header__social {
  width: 45%;
}
.l-header__social .i {
  float: right;
  margin-right: 4px;
}
.l-header__social .i:first-child {
  margin-right: 0;
}
.l-header__social .i:first-child:before {
  margin-right: 0;
}
.l-header__address {
  clear: both;
}
.l-footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 100px;
  padding-right: 100px;
  line-height: 40px;
  zoom: 1;
  color: #fff;
  font-size: 17px;
}
.l-footer:after {
  clear: both;
}
.l-footer .i {
  display: inline-block;
  line-height: 40px;
}
.l-footer .i:before {
  margin-right: 8px;
}
.l-footer__logo {
  float: left;
  background: url("../img/../img/logo-w.png") no-repeat center center;
  width: 145px;
  height: 31px;
  width: 15%;
  margin-top: 5px;
}
.l-footer__made-in-ua {
  float: left;
  width: 17%;
  font-size: 12px;
  text-transform: uppercase;
  text-align: right;
}
.l-footer__made-in-ua .i:before {
  margin-right: 9px;
}
.l-footer__phone,
.l-footer__social,
.l-footer__address {
  float: left;
}
.l-footer__phone {
  width: 20%;
  text-align: right;
  font-size: 19px;
}
.l-footer__social {
  width: 18%;
  text-align: center;
}
.l-footer__address {
  width: 30%;
  text-align: left;
}
.c-hidden-content {
  position: relative;
}
.c-hidden-content__content {
  text-align: center;
  opacity: 0.25;
}
.c-hidden-content__message {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 40px;
  margin-top: -20px;
  font-size: 40px;
  text-transform: uppercase;
  text-align: center;
  line-height: 40px;
  letter-spacing: 0.083em;
  color: #2c5cb8;
}
.c-hidden-content__message--large {
  height: 50px;
  margin-top: -25px;
  font-size: 50px;
  line-height: 50px;
}
.i {
  vertical-align: middle;
}
.i:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
}
.i--flag:before {
  background: url("../img/icons/flag.png") no-repeat center center;
  width: 17px;
  height: 10px;
}
.i--arrow-down:before {
  background: url("../img/icons/arrow-down.png") no-repeat center center;
  width: 47px;
  height: 51px;
}
.i--b-mark:before {
  background: url("../img/icons/b-mark.png") no-repeat center center;
  width: 11px;
  height: 19px;
}
.i--b-phone:before {
  background: url("../img/icons/b-phone.png") no-repeat center center;
  width: 13px;
  height: 19px;
}
.i--b-s-fb:before {
  background: url("../img/icons/b-s-fb.png") no-repeat center center;
  width: 28px;
  height: 30px;
}
.i--b-s-telegram:before {
  background: url("../img/icons/b-s-telegram.png") no-repeat center center;
  width: 29px;
  height: 30px;
}
.i--b-s-vk:before {
  background: url("../img/icons/b-s-vk.png") no-repeat center center;
  width: 28px;
  height: 30px;
}
.i--w-mark:before {
  background: url("../img/icons/w-mark.png") no-repeat center center;
  width: 11px;
  height: 19px;
}
.i--w-phone:before {
  background: url("../img/icons/w-phone.png") no-repeat center center;
  width: 12px;
  height: 17px;
}
.i--w-s-fb:before {
  background: url("../img/icons/w-s-fb.png") no-repeat center center;
  width: 24px;
  height: 27px;
}
.i--w-s-telegram:before {
  background: url("../img/icons/w-s-telegram.png") no-repeat center center;
  width: 25px;
  height: 26px;
}
.i--w-s-vk:before {
  background: url("../img/icons/w-s-vk.png") no-repeat center center;
  width: 24px;
  height: 27px;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding-top: 10%;
  padding-bottom: 10%;
  display: none;
  vertical-align: middle;
  overflow: auto;
  z-index: 9999;
  background: rgba(0,0,0,0.533);
}
.modal.is-active {
  display: block;
}
.modal__window {
  border: 1px solid #98d2f1;
  width: 370px;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background: #fff;
  box-shadow: 0 20px 60px rgba(0,0,0,0.533);
}
.modal__window--wide {
  width: 550px;
}
.modal__bar {
  padding-top: 0.5rem;
  text-align: center;
  background: #fff;
}
.modal__logo {
  display: inline-block;
  margin-top: 8px;
  background: url("../img/logo-small.png") no-repeat center center;
  width: 198px;
  height: 43px;
}
.modal__close {
  float: right;
  margin-right: 0.5rem;
  cursor: pointer;
  background: url("../img/close.png") no-repeat center center;
  width: 19px;
  height: 18px;
}
.modal__body {
  position: relative;
  padding: 1rem;
  font-size: 18px;
  color: #7babe3;
  background: #fff;
}
.modal__footer {
  height: 0.5rem;
  background: #fff;
}
.modal__backdrop {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}
.modal__backdrop.is-active {
  display: block;
}
.form-field {
  margin-top: 14px;
}
.ok-sign {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
}
.input-pl {
  display: inline-block;
  padding: 0.5rem 4rem;
  border: 1px solid #98d2f1;
  color: #7babe3;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.066em;
  background: #fff;
}
.input-pl input {
  margin-left: 8px;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  background: transparent;
  border: 0 solid transparent;
}
.input-pl input:focus {
  outline: none;
}
button.input-pl {
  font-size: 18px;
  background: transparent;
  color: #7babe3;
}
.c-products {
/* @extend $clearfix */
  display: block;
  padding-left: 0;
  padding-right: 0;
  margin-left: -10px;
  margin-right: -10px;
  list-style: none;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.c-products__item {
  display: block;
  display: block;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
  float: left;
  width: 33.33333%;
  height: 480px;
}
.c-products__img {
  display: block;
  height: 410px;
}
.c-products__title {
  display: block;
  font-size: 19px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
}
.c-moar {
  background: url("../img/p-hidden.png") no-repeat center center;
  width: 1189px;
  height: 389px;
  padding-top: 95px;
  padding-bottom: 140px;
  height: auto;
  background-position: top center;
  width: 100%;
}
.c-moar__inner {
  height: 200px;
  line-height: 200px;
  font-size: 60px;
  text-align: center;
  font-weight: 200;
  color: #79d3f3;
  background: rgba(255,255,255,0.8);
}
.c-down {
  display: block;
  text-align: center;
  height: 80px;
  z-index: 3;
  font-size: 23px;
  line-height: 80px;
}
.c-down .i {
  margin-left: 10px;
}
