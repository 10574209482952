@require "../bower_components/stem-stylus-extensions"
@require "../bower_components/stem-stylus-responsive"
@require "../bower_components/stem-stylus-color-scheme"
@require "../bower_components/stem-stylus-grid"
@require "../bower_components/stem-stylus-typo"
// @require "../bower_components/"

CS.color.primary = #fff
CS.color.accent = #98d2f1
CS.color.bg = #79d3f3
CS.color.dtext = #2c5cb8
CS.color.atext = #7babe3

Grid.width = 1220px
Grid.gutter = 20px
Grid.responsive = false
Grid.respond = ()

Path.font = '../fonts/'
pi = Path.img

font-face('DinTextPro', 'pfdintextpro', 'light')
font-face('DinTextPro', 'pfdintextpro', 'regular')
// font-face('DinTextPro', 'pfdintextpro', 'italic')
// font-face('DinTextPro', 'pfdintextpro', 'bold')

reset-normalize()
typo-text()


fill-gradient(color, dv=100px)
    bg: url(pi + 'gr-' + color '.png') repeat-y center

    // s = Grid.width - Grid.gutter + dv
    // dv /= 2

    // bg: linear-gradient(90deg, transparent 0, color dv, color s - dv, transparent s) repeat-y center
    // bg-size: s


bimg(pt)
    pt = pi + pt
    bg url(pt) no-repeat center center
    size(image-size(pt))


html
    border-box()
    color cs(dtext)

body
    f-family DinTextPro

    min-width: Grid.width

    &.is-not-scrollable
        overflow hidden

a
    text-decoration none

//
// Panel
//

@b l-panel
    @m
        &top-bar
            @p f
                size 11px

            bg-color cs(primary)
            color cs(atext)

            & .l-panel__container
                pv g-space(2)

        &header
            bg-color cs(accent)
            bg-image: url(pi + 'bg-orange.png')
            bg-repeat repeat
            color #fff

            & .l-panel__container
                pv g-space(3)

            & .l-panel__bg
                fill-gradient('orange')

        &content
            position relative
            z-index 3
            mt -80px

            bg: cs(bg) url(pi + 'bg-pt-blue.png') repeat

            & .l-panel__container
                pv g-space(0)

        &footer
            bg: #9e9e9e url(pi + 'bg-pt-grey.png') repeat

    @e
        &container
            g-center()


//
// Header
//

@b l-header
    clear fix

    @e
        &container
            g-center(1000px)
            position relative

        &bg
            position relative
            z-index 1
            width 100%
            height 100%
            height 100vh

            bg url(pi 'kdpv.png') no-repeat center center
            bg-size cover

        &lead
            position absolute
            ml -84px
            left 50%
            bottom 136px

            fs 27px

            color #fff

        &bar
            position relative
            height 85px
            margin-bottom 0 - @height

            bg #fff
            color cs(atext)
            z-index 2

        &logo
            float left
            margin-top 14px
            bimg('logo.png')

        &text
            float left
            margin-top 24px
            ml 30px
            fs 14px

        &contact
            width 270px
            float right
            margin-top 10px

            @p f
                size 15px
                height 31px

            & .i
                // letter-spacing .066em

                &:before
                    mr 9px

        &phone
        &social
            float left

        &phone
            width 55%

            fs 16px

        &social
            width 45%

            & .i
                float right
                mr 4px

                &:first-child
                    mr 0

                    &:before
                        mr 0

        &address
            clear both

//
// Footer
//

@b l-footer
    pv g-space(3)
    ph 100px
    line-height 40px

    clear fix

    color #fff
    font-size 17px

    & .i
        display inline-block
        line-height 40px

        &:before
            mr 8px

    @e
        &logo
            float left
            bimg(pi + 'logo-w.png')
            width 15%
            mt 5px

        &made-in-ua
            float left
            width 17%

            @p f
                size 12px
                transform uppercase
                align right

            & .i
                &:before
                    mr 9px

        &phone
        &social
        &address
            float left

        &phone
            width 20%
            text-align right
            font-size 19px

        &social
            width 18%
            text-align center

        &address
            width 30%
            text-align left


//
// Hidden content
//

@b c-hidden-content
    position relative

    @e
        &content
            text-align center
            opacity .25

        &message
            position absolute
            top 50%
            left 0
            width 100%
            height 40px
            mt 0 - (@height / 2)

            @p f
                size @height
                transform uppercase
                align center
                height @height
            letter-spacing .083em

            color cs(dtext)

            @m
                &large
                    height 50px

                    mt 0 - (@height / 2)

                    f-size @height
                    f-height @height



//
// Icon
//

@b i
    vertical-align middle

    &:before
        content ''
        display inline-block
        vertical-align middle
        mt -3px


    for pic in ('flag' 'arrow-down' 'b-mark' 'b-phone' 'b-s-fb' 'b-s-telegram' 'b-s-vk' 'w-mark' 'w-phone' 'w-s-fb' 'w-s-telegram' 'w-s-vk')
        @m {pic}
            &:before
                bimg('icons/' + pic + '.png')


//
// Modal
//

@b modal
    position fixed
    top 0
    left 0
    bottom 0
    right 0
    width 100%
    height 100%
    pv 10%
    display none
    vertical-align middle
    overflow auto
    z-index 9999

    bg #0008

    @s
        &active
            display block

    @e
        &window
            border 1px solid cs(accent)
            width 370px
            max-width 550px
            mh auto

            text-align center

            bg #fff
            box-shadow 0 20px 60px #0008

            @m
                &wide
                    width 550px

        &bar
            pt g-space(2)
            text-align center

            bg #fff

        &logo
            display inline-block
            margin-top 8px

            bimg('logo-small.png')

        &close
            float right
            mr g-space(2)

            cursor pointer

            bimg('close.png')

        &body
            position relative
            padding g-space(3)

            font-size 18px

            color cs(atext)
            bg #fff

        &footer
            height g-space(2)

            bg #fff


        &backdrop
            position absolute
            display none
            top 0
            left 0
            width 100%
            height 100%
            bg #fff

            @s
                &active
                    display block


.form-field
    mt 14px

.ok-sign
    pv g-space(3)

    text-align center

.input-pl
    display inline-block
    padding g-space(2) g-space(5)

    border 1px solid cs(accent)
    color cs(atext)

    @p f
        size 24px
        height 24px

    letter-spacing .066em

    input
        ml 8px

        @p f
            size inherit
            height inherit
        letter-spacing inherit

        bg transparent
        border 0 solid transparent

        &:focus
            outline none

    bg #fff

    button&
        fs 18px

        bg transparent
        color cs(atext)



@b c-products
    g-container()
    pv g-space(3)

    @e
        &item
            display block
            g-columns(8)
            height 480px

        &img
            display block
            height 410px

        &title
            display block
            fs 19px
            pv g-space(2)
            text-align center


@b c-moar
    bimg('p-hidden.png')
    pt 95px
    pb 140px
    height auto
    bg-position top center

    width 100%

    @e
        &inner
            height 200px

            line-height 200px
            fs 60px
            text-align center
            fw 200

            color cs(bg)
            bg #fffc




@b c-down
    display block
    text-align center
    height 80px
    z-index 3

    fs 23px
    line-height @height


    & .i
        margin-left 10px